import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Constant } from 'app/config/constants';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { RegexTime } from 'app/module/timetable-editor/timetable-editor.component';
import { DialogService } from 'app/service/dialog.service';
import { Observable, from, throwError } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class TicketEditorService {
  ticketEditorUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/ticket-editor';
  urlCustomer = 'https://management-api.lecip-r05-dev.net/v1/';

  crewAccount = [
    { operator: 'VHT', employeeAccountId: 'thanhdh', password: 'xxx1' },
    { operator: 'AD', employeeAccountId: 'datnt', password: 'xxx1' },
    { operator: 'COSPAS', employeeAccountId: 'vinhnd', password: 'xxx1' },
    { operator: 'DK', employeeAccountId: 'truongnt', password: 'xxx1' }
  ];

  /**
   * ticket url
   */
  private ticketUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/ticket-editor';

  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private dialogService: DialogService,
    private translateService: TranslateService
  ) {}

  /**
   * read excels from client
   * @param files
   * @param regexTime
   * @returns
   */
  readExcelsFromClient(files: File[], regexTime: RegexTime, timeDateLine: string): Observable<any> {
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file);
    }
    formData.append('regexTime', JSON.stringify(regexTime));
    formData.append('timeDateLine', timeDateLine);
    return this.http.post<any>(`${this.ticketUrl}/read-excels`, formData);
  }

  /**
   * write excel
   * @param timetable
   * @returns
   */
  public writeExcel(timetableId: Number, dayInMonth: number, month: number): Observable<any> {
    const params = new HttpParams()
      .set('language', this.commonService.getCommonObject().setting?.language)
      .set('dayInMonth', `${dayInMonth}`)
      .set('month', `${month}`);
    return this.http.post(`${this.ticketUrl}/write-excel`, timetableId, {
      responseType: 'blob',
      observe: 'response',
      params: params
    });
  }

  /**
   * Get list crew account
   */
  getListCrewAccount(): Array<any> {
    return this.crewAccount;
  }

  /**
   * addCrewAccount
   * @param formData
   * @returns
   */
  addCrewAccount(formData: any): boolean {
    this.crewAccount.push(formData);
    return true;
  }

  /**
   * deleteCrewAccount
   * @param listAccountId
   * @returns
   */
  deleteCrewAccount(listAccountId: any): boolean {
    listAccountId.forEach(accountId => {
      const index = this.crewAccount.findIndex(account => account.employeeAccountId == accountId);
      if (index > -1) this.crewAccount.splice(index, 1);
    });
    return true;
  }

  /**
   * get Languages Default
   * @returns
   */
  public getLanguagesDefault(): Observable<any> {
    return this.http.get<any>(`${this.ticketEditorUrl}/get-languages`);
  }

  /**
   * getTickets
   * @param informationAccount
   * @returns
   */
  public getTickets(informationAccount: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const params = new HttpParams().set('companyId', informationAccount.compnanyid.toString());
    const options = { headers, params };
    return this.http.get<any>(`${informationAccount.api_url}tickets`, options);
  }

  /**
   * getTicket
   * @param informationAccount
   * @param ticketId
   * @returns
   */
  public getTicket(informationAccount: any, ticketId: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const params = new HttpParams().set('ticketId', ticketId);
    const options = { headers, params };
    return this.http.get<any>(`${informationAccount.api_url}ticket`, options);
  }

  /**
   * getTickets
   * @param companyId
   * @param appId
   * @returns
   */
  public getTicketUpdate(informationAccount: any, ticketId: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const params = new HttpParams().set('ticketId', ticketId);
    const options = { headers, params };
    return this.http.get<any>(`${informationAccount.api_url}ticket-update`, options);
  }

  /**
   * getTickets
   * @param companyId
   * @param appId
   * @returns
   */
  public getSalesTickets(informationAccount: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const params = new HttpParams().set('companyId', informationAccount.compnanyid.toString());
    const options = { headers, params };
    return this.http.get<any>(`${informationAccount.api_url}sale-tickets`, options);
  }

  /**
   * getTickets
   * @param companyId
   * @param appId
   * @returns
   */
  public getSalesTicket(informationAccount: any, saleTicketId: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const params = new HttpParams().set('saleTicketId', saleTicketId);
    const options = { headers, params };
    return this.http.get<any>(`${informationAccount.api_url}sale-ticket`, options);
  }

  /**
   * getTickets
   * @param companyId
   * @param appId
   * @returns
   */
  public getSalesTicketUpdate(informationAccount: any, saleTicketId: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const params = new HttpParams().set('saleTicketId', saleTicketId);
    const options = { headers, params };
    return this.http.get<any>(`${informationAccount.api_url}sale-ticket-update`, options);
  }

  /**
   * postSaleTicket
   * @param informationAccount
   * @param payload
   * @returns
   */
  public postSaleTicket(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    return this.http.post<any>(`${informationAccount.api_url}sale-ticket`, payload, { headers: headers });
  }

  /**
   * putSaleTicket
   * @param informationAccount
   * @param payload
   * @returns
   */
  public putSaleTicket(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    return this.http.put<any>(`${informationAccount.api_url}sale-ticket`, payload, { headers: headers });
  }

  /**
   * deleteSaleTicket
   * @param informationAccount
   * @param payload
   * @returns
   */
  public deleteSaleTicket(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const options = {
      headers: headers,
      body: payload
    };
    return this.http.delete<any>(`${informationAccount.api_url}sale-ticket`, options).pipe(
      catchError(error => {
        const errorMessage =
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  /**
   * deleteSaleTickets
   * @param informationAccount
   * @param payloads
   * @returns
   */
  public deleteSaleTickets(informationAccount: any, payloads: any): Observable<any> {
    return from(payloads).pipe(concatMap(payload => this.deleteSaleTicket(informationAccount, payload)));
  }

  /**
   * postSaleTicketUpdate
   * @param informationAccount
   * @param payload
   * @returns
   */
  public postSaleTicketUpdate(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    return this.http.post<any>(`${informationAccount.api_url}sale-ticket-update`, payload, { headers: headers });
  }

  /**
   * deleteSaleTicketUpdate
   * @param informationAccount
   * @param saleTicketId
   * @returns
   */
  public deleteSaleTicketUpdate(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const options = {
      headers: headers,
      body: payload
    };
    return this.http.delete<any>(`${informationAccount.api_url}sale-ticket-update`, options).pipe(
      catchError(error => {
        const errorMessage =
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  /**
   * deleteSaleTicketUpdates
   * @param informationAccount
   * @param payloads
   * @returns
   */
  public deleteSaleTicketUpdates(informationAccount: any, payloads: any): Observable<any> {
    return from(payloads).pipe(concatMap(payload => this.deleteSaleTicketUpdate(informationAccount, payload)));
  }

  /**
   * getSpots
   * @param companyId
   * @param appId
   * @returns
   */
  public getSpots(informationAccount: any, appId: string): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const params = new HttpParams().set('appId', appId);
    const options = { headers, params };
    return this.http.get<any>(`${informationAccount.api_url}spots`, options);
  }

  /**
   * getTickets
   * @param companyId
   * @param appId
   * @returns
   */
  public postSpot(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const options = { headers };
    return this.http.post<any>(`${informationAccount.api_url}spot`, payload, options);
  }

  /**
   * getTickets
   * @param companyId
   * @param appId
   * @returns
   */
  public putSpot(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const options = { headers };
    return this.http.put<any>(`${informationAccount.api_url}spot`, payload, options);
  }

  /**
   * getTickets
   * @param companyId
   * @param appId
   * @returns
   */
  public deleteSpot(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const options = {
      headers: headers,
      body: payload
    };
    return this.http.delete(`${informationAccount.api_url}spot`, options).pipe(
      catchError(error => {
        const errorMessage =
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  /**
   * deleteSpotsSequentially
   * @param informationAccount
   * @param payloads
   * @returns
   */
  public deleteSpotsSequentially(informationAccount: any, payloads: any[]): Observable<any> {
    return from(payloads).pipe(concatMap(payload => this.deleteSpot(informationAccount, payload)));
  }

  /**
   * getTickets
   * @param companyId
   * @param appId
   * @returns
   */
  public postTicket(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    return this.http.post<any>(`${informationAccount.api_url}ticket`, payload, { headers: headers });
  }

  /**
   * getTickets
   * @param companyId
   * @param appId
   * @returns
   */
  public putTicket(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    return this.http.put<any>(`${informationAccount.api_url}ticket`, payload, { headers: headers });
  }

  /**
   * deleteTicket
   * @param informationAccount
   * @param payload
   * @returns
   */
  public deleteTicket(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const options = {
      headers: headers,
      body: payload
    };
    return this.http.delete(`${informationAccount.api_url}ticket`, options);
  }

  /**
   * deleteTicketsSequentially
   * @param informationAccount
   * @param payloads
   * @returns
   */
  public deleteTicketsSequentially(informationAccount: any, payloads: any[]): Observable<any> {
    return from(payloads).pipe(concatMap(payload => this.deleteTicket(informationAccount, payload)));
  }

  /**
   * deleteTicket
   * @param informationAccount
   * @param payload
   * @returns
   */
  public deleteTicketUpdate(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const options = {
      headers: headers,
      body: payload
    };
    return this.http.delete(`${informationAccount.api_url}ticket-update`, options);
  }

  /**
   * deleteTicketUpdatesSequentially
   * @param informationAccount
   * @param payloads
   * @returns
   */
  public deleteTicketUpdatesSequentially(informationAccount: any, payloads: any[]): Observable<any> {
    return from(payloads).pipe(concatMap(payload => this.deleteTicketUpdate(informationAccount, payload)));
  }

  /**
   * getTickets
   * @param companyId
   * @param appId
   * @returns
   */
  public postTicketUpdate(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    return this.http.post<any>(`${informationAccount.api_url}ticket-update`, payload, { headers: headers });
  }
  /**
   * getInformationAccount
   * @returns
   */
  public getInformationAccount(): Observable<any> {
    return this.http.get<any>(`${this.ticketEditorUrl}/get-information-account`);
  }

  /**
   * putApplication
   * @param payload
   * @param informationAccount
   * @returns
   */
  public putApplication(payload: any, informationAccount: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    return this.http
      .put<any>(`${informationAccount.api_url}application`, payload, { headers: headers })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  /**
   * postApplication
   * @param payload
   * @param informationAccount
   * @returns
   */
  public postApplication(payload: any, informationAccount: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    return this.http
      .post<any>(`${informationAccount.api_url}application`, payload, { headers: headers })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  /**
   * getCompanies
   * @param informationAccount
   * @returns
   */
  getCompanies(informationAccount: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const params = new HttpParams().set('companyId', informationAccount.compnanyid);
    const options = { headers, params };
    return this.http.get<any>(`${informationAccount.api_url}operators`, options).pipe(
      catchError(error => {
        const errorMessage =
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  /**
   *
   * @param informationAccount
   * @param payload
   * @returns
   */
  postImage(informationAccount: any, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    return this.http.post<any>(`${informationAccount.api_url}resources/image`, payload, { headers: headers });
  }

  /**
   * generateQRCode
   * @param url
   * @returns
   */
  generateQRCode(url: string): Observable<any> {
    return this.http.post(`${this.ticketUrl}/generateQRCode`, url, { responseType: 'text' });
  }
}
